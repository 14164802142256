import { render, staticRenderFns } from "./step2.vue?vue&type=template&id=4ae05485&scoped=true&lang=pug&"
import script from "./step2.vue?vue&type=script&lang=js&"
export * from "./step2.vue?vue&type=script&lang=js&"
import style0 from "./step2.vue?vue&type=style&index=0&id=4ae05485&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae05485",
  null
  
)

export default component.exports