<template lang="pug">
.content-step2
  loading-logo(v-if="loading")
  template(v-else)
    om-switch#doi.doi-switch.mb-2(
      v-model.sync="doubleOptIn"
      :label="$t('integrationFlow.activeCampaign.doi')"
    )
    template(v-if="doubleOptIn")
      om-select#formName.mt-5(
        v-model="formId"
        :options="forms"
        :label="$t('integrationFlow.activeCampaign.formHelper', { type: 'ActiveCampaign' })"
        :placeholder="$t('integrationFlow.integrationModal.selectAForm')"
      )
      .form-select-info.mt-2 {{ $t('integrationFlow.activeCampaign.formSelectInfo') }}
    template(v-else)
      om-select#listName(
        v-model="listId"
        :options="lists"
        :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'ActiveCampaign' })"
        :placeholder="$t('integrationFlow.integrationModal.selectAList')"
        :error="isListInvalidByAlert"
        @input="onListChange"
      )
      #listName.error-msg.mt-3(v-if="isListInvalidByAlert" key="error-message")
        span.text-danger(v-html="$t('integrationFlow.integrationModal.general.invalidList')")
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';
  import { getIntegrationsFixedFields } from '@om/integrations';
  import validationByAlert from '@/mixins/integration/validationByAlert';
  import trackStep2Mixin from '@/mixins/integration/trackStep2';

  export default {
    mixins: [validationByAlert, trackStep2Mixin],

    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        step2Fields: ['formId', 'listId', 'doubleOptIn'],
        loading: false,
        listId: null,
        lists: [],
        formId: null,
        forms: [],
        hasError: false,
      };
    },

    computed: {
      doubleOptIn: {
        get() {
          return this.settings.convertedSettings?.doubleOptIn || false;
        },
        set(value) {
          const settings = this.settings;
          settings.convertedSettings.doubleOptIn = value;
          this.$emit('update:settings', { ...settings });
          this.emitIsModified();
        },
      },
    },

    watch: {
      listId(option) {
        this.updateListSetting(option.key);
      },
      formId(option) {
        this.updateFormSetting(option.key);
      },
    },

    async mounted() {
      this.loading = true;
      // IMPORTANT to load data then set model (behavior of om-select)
      const { lists, forms, fields, tags } = await this.loadData();

      this.forms = forms;

      const settings = { ...this.settings };
      settings.fields = fields;
      settings.tags = tags;
      this.$emit('update:settings', settings);

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      const { listId, formId } = this.settings.convertedSettings;

      // om-select selected option only needs "key" from object
      this.listId = listId ? { key: listId } : null;
      this.formId = formId ? { key: formId } : null;
      this.loading = false;
    },

    methods: {
      async loadData() {
        const integrationService = new IntegrationService(this.$apollo);
        const result = {};

        try {
          const { success, lists, forms, sourceFields, tags } =
            await integrationService.fetchIntegrationData(this.settings.type, this.settings.id);

          if (success) {
            result.lists = lists
              .sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              })
              .map((list) => ({
                key: list.id,
                value: list.name,
              }));
            result.forms = forms
              .sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              })
              .map((form) => ({
                key: form.id,
                value: form.name,
              }));
            result.tags = tags.sort((a, b) => {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            const integrationFixedFields = getIntegrationsFixedFields('activeCampaign');
            const fixedFields = Object.keys(integrationFixedFields).map((key) => {
              return {
                id: integrationFixedFields[key],
                name: this.$t(`integrations.activeCampaign.fields.${key}`, { key }),
              };
            });
            const sortedSourceField = sourceFields.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });

            result.fields = [...fixedFields, ...sortedSourceField];
          } else {
            this.errorNotification(result.error);
          }
        } catch (e) {
          this.errorNotification(e.message);
        }

        return result;
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },
      updateListSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', settings);
        this.emitIsModified();
      },
      updateFormSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.formId = value;
        this.$emit('update:settings', settings);
        this.emitIsModified();
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .form-select-info
    font-size: 12px
    color: $om-gray-600

  .doi-switch
    width: 170px
    margin-left: 0
    ::v-deep .switch-label
      margin-top: 8px
</style>
